@use '../../util/mixins';
@import '../../util/variables';

#footer {
  background: $color-secondary-6;
}

footer {
  margin: auto 7% 0 7%;
  padding: 4rem 2rem;

  @media screen and (max-width: $screen-xss) {
    padding: 4rem 1rem;
  }

  .extras {
    @include mixins.anchor($text-color-light);
    color: darken($text-color-light, 25%);
  }

  .visits {
    background: $color-primary-8;
    color: $text-color-dark;
    padding: 0.2rem 0.5rem;
    border-radius: $border-radius-1;
  }

  .row {
    align-items: center;
  }

  .social-icon {
    font-size: 2rem;
    margin: 0 2%;
    &:hover {
      color: darken($text-color-light, 15%);
    }
  }
  .divider {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $color-secondary-3;
    margin: 1rem 0;
  }
}

.copyright {
  background: lighten($color-secondary-6, 10%);
  padding: 2% 7%;
  text-align: center;
  color: darken($text-color-light, 25%);
  p {
    color: darken($text-color-light, 25%);
  }
}
